"use client";
import Link from "next/link";
import Image from "next/image";
import { WindowWithDataLayer } from "@/app/types/WindowDataLayer";
import { usePathname } from "next/navigation";
import { ImgLinkProps } from "@/app/types/ImgLinkProps.type";
import handleDataLayerOnClick from "@/app/helpers/analytics/handleDataLayerOnClick";

declare const window: WindowWithDataLayer;
export default function ImageLink({
	post,
	blockData,
	cardPosition,
	loading,
}: ImgLinkProps) {
	const pathName = usePathname();
	let imgSizes = "60vw";
	if (blockData?.tipoBloque === "BloqueAperturaLive") {
		imgSizes =
			"(min-width: 1440px) 14vw, (min-width: 1280px) 13vw, (min-width: 1024px) 24vw, (min-width: 820px) 25vw, (min-width:768px) 23vw, (min-width:540px) 38vw, 50vw";
	}
	const trailingPath = `${post?.path}/`?.replace(/\/+$/, "/");

	let stylesImg = {};

	if (blockData.tipoBloque === "BloqueVideosDemanda") {
		stylesImg = { maxWidth: "320px" };
	}
	return (
		<>
			<Link
				href={trailingPath ?? {}}
				title={post?.title}
				prefetch={false}
				onClick={() =>
					handleDataLayerOnClick(blockData, cardPosition, pathName)
				}
			>
				<Image
					src={post?.thumbnail?.imageUrl?.webp ?? "/assets/placeholder_.svg"}
					alt={post?.thumbnail?.alt ?? "Imagen default"}
					width={post?.thumbnail?.width ?? 320}
					height={post?.thumbnail?.height ?? 180}
					sizes={imgSizes}
					loading="lazy"
					placeholder="blur"
					blurDataURL={`${
						process.env.NEXT_PUBLIC_BLURIMAGE ||
						"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAADElEQVR4nGP4b+YAAAOtAXam2gnIAAAAAElFTkSuQmCC"
					}`}
					onError={() => "/assets/placeholder_.svg"}
					style={stylesImg}
				/>
			</Link>
		</>
	);
}
