import { secondsToISO8601 } from "@/app/helpers/timeHelpers/timeHelpers";

type VideoObjectSchema = {
	"@context": string;
	"@type": string;
	name: string;
	description: string;
	thumbnailUrl: [string];
	uploadDate: string;
	duration: string;
	contentUrl?: string;
	embedUrl?: string;
	keywords?: [string];
	genre?: string
	author: AuthorType;
	publication?: PublicationType;
	isStreaming?: boolean;
}

type AuthorType = {
	"@type": string;
	name: string;
	url: string
}

type PublicationType = {
	"@type": string;
	name: string;
	description: string;
	isLiveBroadcast: boolean;
	startDate: string;
	endDate: string
}

export function VideoObject({mainVid}:any) {
	const baseurl = process.env.NEXT_PUBLIC_BASE_URL?.replace(/\/+$/, "");
  const mcpID = mainVid?.cmsId;
  const VideoObject:VideoObjectSchema = {
		"@context": "https://schema.org",
		"@type": "VideoObject",
		name: mainVid?.title,
		description: mainVid?.summary?.replace(/(<([^>]+)>)/gi, ""),
		thumbnailUrl: [mainVid?.thumbnail?.imageUrl?.webp ?? `${baseurl}/assets/screen_n.png`],
		uploadDate: mainVid?.uploadDate,
		duration: secondsToISO8601(mainVid?.duration),
		contentUrl: mainVid?.contentUrl,
		embedUrl: mainVid?.isBroadcastEvent ? `${baseurl}/preview-video/?ui=${mainVid?.streaming?.ui}&uid=${mainVid?.streaming?.uid}&path=${mainVid?.streaming?.path}` : `${baseurl}/preview-video/?idvideo=${mcpID}`,
		keywords: mainVid?.term?.map((item:any) => item?.name),
		genre: "News & Politics",
		author: {
			"@type": "Thing",
			name: "Redacción N+",
			url: `${baseurl}/noticieros/talentos/`
		},
	}
	if(mainVid?.isBroadcastEvent){
		VideoObject.publication = {
			"@type":"BroadcastEvent",
			name: mainVid?.title,
			description: mainVid?.summary?.replace(/(<([^>]+)>)/gi, ""),
			isLiveBroadcast: mainVid?.isBroadcastEvent,
			startDate: mainVid?.startDate,
			endDate: mainVid?.endDate
		}
	}
  return (
		<script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(VideoObject, null, 2) ?? ""}} />
	)
}
