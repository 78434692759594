export default function NewsArticle({ mainNews }: any) {
	const baseurl = process.env.NEXT_PUBLIC_BASE_URL?.replace(/\/+$/, "")!;
	const NewsArticle = {
		"@context": "https://schema.org",
		"@type": "NewsArticle",
		mainEntityOfPage: {
			"@type": "WebPage",
			"@id": `${baseurl}${mainNews?.path}`,
		},
		headline: mainNews?.title,
		description: mainNews?.summary?.replace(/(<([^>]+)>)/gi, ""),
		genre: "News & Politics",
		image: {
			"@type": "ImageObject",
			url: mainNews?.thumbnail?.imageUrl?.webp ?? `${baseurl}/assets/screen_n.png`,
		},
		author: {
			"@type": "Person",
			name: "Redacción N+",
			url: `${baseurl}/noticieros/talentos/`,
		},
		articleBody: mainNews?.summary?.replace(/(<([^>]+)>)/gi, ""),
		datePublished: mainNews?.dateTime,
		dateModified: mainNews?.dateModified ? mainNews?.dateModified : mainNews?.dateTime,
	};

	return (
		<script
			type="application/ld+json"
			dangerouslySetInnerHTML={{ __html: JSON.stringify(NewsArticle, null, 2) ?? "" }}
		/>
	);
}
