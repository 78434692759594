import dynamic from "next/dynamic";
import fetchApolloData from "@/app/helpers/fetchApolloData/fetchApolloData";
import GetPressHomeHome from "@/app/queries/getPressRoomHome";
import { upperCamelCase } from "@/app/helpers/upperCamelCase/upperCamelCase";
import { GetPressRoomHome } from "./interface/PressRoom.interface";
import pressRoomStyle from "@/app/styles/Blocks/BlockPressRoomHome.module.scss";
const Breadcrumb = dynamic(
	() => import("@/app/components/molecules/Breadcrumb/Breadcrumb")
);
const PressRoomHomePrincipal = dynamic(
	() => import("./PressRoomHomePrincipal")
);
const PressRoomHomeCards = dynamic(() => import("./PressRoomHomeCards"));

type BlockPressRoomProps = {
	type: string;
	id: string;
	position?: number;
	total?: number;
};

export const BlockPressRoom = async ({
	id,
	type,
	total,
	position = 0,
}: BlockPressRoomProps) => {
	const { data, ...rest } = await fetchApolloData(GetPressHomeHome, {
		pressRoomId: id,
	});
	if (rest?.errors) {
		console.error(rest?.errors);
	}
	if (!data?.getPressRoomHome) return <></>;
	const {
		blockTitle,
		blockSubtitle,
		banner_image_desktop,
		editorialTitle,
		relationContent,
		published,
		path,
		isPrincipalContent,
		cmsName,
	}: GetPressRoomHome = data?.getPressRoomHome || {};
	const blockType = type.replace("block_content--", "").split("_").join(" ");

	const blockDataLayer = {
		event: "select_content",
		content_type: "SdP-Card",
		seccion: path,
		content_name: editorialTitle || undefined,
		eventAction: "click",
		tipoBloque: upperCamelCase(blockType) || undefined,
		tipoElemento: "LinkText",
		tituloBloque: blockTitle ?? undefined,
		colorBloque: "Light",
		countWidget: total,
		descripcionBloque: cmsName,
		estiloBloque: undefined,
		subBloque: undefined,
		tamanoBloque: undefined,
	};

	const loading =
		position === 0 || position === 1 || position === 2 ? "eager" : "lazy";
	return (
		<>
			<section
				className={`${pressRoomStyle["pressRoomHome"] + " BlockPressRoom"}`}
			>
				<header className={`${pressRoomStyle["pressRoomHome__wrapperHeader"]}`}>
					<Breadcrumb getLasted />

					<h1 className={`${pressRoomStyle["pressRoomHome__title"]}`}>
						{blockTitle}
					</h1>
					{blockSubtitle && (
						<h2 className={`${pressRoomStyle["pressRoomHome__subTitle"]}`}>
							{blockSubtitle}
						</h2>
					)}
				</header>
				{isPrincipalContent && (
					<PressRoomHomePrincipal
						path={path}
						editorialTitle={editorialTitle}
						imageDesktop={banner_image_desktop}
						loading={loading}
						published={published}
						blockDataLayer={blockDataLayer}
					/>
				)}
				<div className={`${pressRoomStyle["pressRoomHome__wrapperContent"]}`}>
					<div className={`${pressRoomStyle["pressRoomHome__wrapperInfo"]}`}>
						<span
							className={`${pressRoomStyle["pressRoomHome__wrapperInfo-1"]}`}
						>
							Mantente informado con nuestros boletines
						</span>
					</div>

					<PressRoomHomeCards
						cards={relationContent}
						loading={loading}
						total={total}
						cmsName={cmsName}
						blockType={blockType}
					/>
				</div>
			</section>
		</>
	);
};
export default BlockPressRoom;
