import dynamic from "next/dynamic";
import fetchApolloData from "@/app/helpers/fetchApolloData/fetchApolloData";
import GetBlockNewsletter from "@/app/queries/getBlockNewsletter";
import { BlockProps } from "@/app/types/BlockProps.type";
import styles from "@/app/styles/Blocks/BloqueNewsletter.module.scss";
const NewsletterForm = dynamic(
	() => import("@/app/components/molecules/NewsletterForm/NewsletterForm")
);
const NewsletterPolitics = dynamic(
	() =>
		import("@/app/components/molecules/NewsletterPolititcs/NewsletterPolitics")
);
const SocialShareButtons = dynamic(
	() =>
		import("@/app/components/molecules/SocialShareButtons/SocialShareButtons")
);

type NewsLetterType = {
	id: string;
	type: string;
	htmlbody: string;
};
export const BlockNewsletter = async ({ type, id }: BlockProps) => {
	const { data, ...rest } = await fetchApolloData(GetBlockNewsletter, {
		getBlockNewsletterId: id,
	});
	if (rest?.errors) {
		console.error(rest?.errors?.networkError?.result?.errors[0]);
	}

	const blockData: NewsLetterType = data?.getBlockNewsletter;

	return (
		<>
			{blockData.type === "Resultados" && (
				<SocialShareButtons
					title={
						"Resultados Elecciones 2024 en México: Quién Ganó las Votaciones"
					}
					description={
						"Checa en N+ los resultados de las Elecciones 2024 en México y descubre quién ganó este 2 de junio en el proceso electoral más grande en la historia del país."
					}
					link={"/nacional/politica/resultados-elecciones-2024"}
					isVariantStoryPage={true}
				/>
			)}
			{blockData.type === "Politica" && <NewsletterPolitics />}
			{blockData.type !== "Politica" && blockData.type !== "Resultados" && (
				<section className={styles?.newsletter}>
					<div className={styles?.newsletter__inner_wrapper}>
						<p className={styles?.newsletter__text}>
							<strong>Suscríbete</strong>
						</p>
						<p className={styles?.newsletter__text}>
							RECIBE <strong>BOLETINES N+</strong> EN TU CORREO
						</p>
						<NewsletterForm />
					</div>
				</section>
			)}
		</>
	);
};
export default BlockNewsletter;
