import fetchApolloData from "@/app/helpers/fetchApolloData/fetchApolloData";
import { BlockProps } from "@/app/types/BlockProps.type";
import GetBlockTitleDark from "@/app/queries/getBlockTitleDark";
import { TitleTimeline } from "./types/TitleTimeline.type";
import styles from "@/app/styles/Blocks/BlockTitleTimeline.module.scss";

export const BlockTitleTimeline = async ({ id, type }: BlockProps) => {
	const { data, ...rest } = await fetchApolloData(GetBlockTitleDark, {
		getBlockTitleDarkId: id,
	});

	if (rest?.errors) {
		console.error(rest?.errors);
	}
	const blockData: TitleTimeline = data?.getBlockTitleDark;
	const { isDark } = blockData;
	return (
		<section
			className={
				isDark
					? `${styles?.titleTimeline} ${styles?.dark}`
					: `${styles?.titleTimeline}`
			}
		>
			<div className={styles?.titleTimeline__innerWrapper}>
				<h2>{blockData?.title}</h2>
				<p>{blockData?.description}</p>
			</div>
		</section>
	);
};
export default BlockTitleTimeline;
