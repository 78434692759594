import dynamic from "next/dynamic";
import {
	BloqueHeroGrid,
	BloqueAperturaLive,
	BloqueDetailProgram,
	BloqueVOD,
	BloqueListadoProgramas,
	BloqueWebStories,
	BloqueIndependiente,
	BloqueBotoneraHorizontal,
	BloqueTalentsTabs,
	BloqueProgramas,
	BloqueMosaico,
	BloqueTextoHtml,
	BloqueHero,
	BloqueAperturaInf,
	BloqueTopBanner,
	BloqueMinutoXMinuto,
	BlockNewsletter,
	BlockTopFive,
	BlockCarouselNews,
	BlockDetailVideoLoader,
	BlockTitleTimeline,
	BlockPressRoom,
	DiscoverLoader,
	SectionTitle,
	BloqueDetailTalent,
	BloqueMapboxMap,
	BlockSlideShow,
	BlockLiveBlog,
} from "../components/organisms/";
import { BlockToggleMap } from "../components/organisms/BlockToggleMap/BlockToggleMap";
import BloqueUniversal from "@/app/components/organisms/BloqueUniversal/BloqueUniversal";
import LiveBlog from "@/app/site-tools/structured-data/LiveBlog";
import NewsArticle from "@/app/site-tools/structured-data/NewsArticle";
import { getPinnedById } from "@/actions";
import { VideoObject } from "@/app/site-tools/structured-data/VideoObject";
import BloqueStoryPage from "@/app/components/organisms/BloqueStoryPage/BloqueStoryPage";
const BloqueParrillaRegional = dynamic(
	() =>
		import(
			"@/app/components/organisms/BloqueParrillaRegional/BloqueParrillaRegional"
		)
);
const GridLoader = dynamic(
	() => import("@/app/components/organisms/Grid/GridLoader")
);
const BloqueVideoVertical = dynamic(
	() =>
		import("@/app/components/organisms/BloqueVideoVertical/BloqueVideoVertical")
);
const BloqueMinutoXMinutoStandalone = dynamic(
	() =>
		import(
			"@/app/components/organisms/BloqueMinutoXMinutoStandalone/BloqueMinutoXMinutoStandalone"
		)
);
const Breadcrumb = dynamic(
	() => import("@/app/components/molecules/Breadcrumb/Breadcrumb")
);

export default function WidgetsMapping(
	type: any,
	id: any,
	pagePath?: string,
	position?: any,
	params?: any,
	total?: number,
	content?: any,
	enabledStructureData: boolean = false
) {
	const newsArt = { ...content };

	if (type === "node--liveblog") {
		newsArt.summary = content?.summary?.description || "";
	}

	switch (type) {
		case "block_content--bloque_apertura_live":
			return (
				<BloqueAperturaLive
					type={type}
					id={id}
					position={position}
					total={total}
				/>
			);
		case "block_content--bloque_detail_program":
			return (
				<BloqueDetailProgram
					type={type}
					id={id}
					position={position}
					total={total}
				/>
			);
		case "block_content--parrilla_programacion_regional":
			return <BloqueParrillaRegional type={type} id={id} />;
		case "block_content--bloque_videos_demanda":
			return (
				<BloqueVOD
					type={type}
					id={id}
					position={position}
					total={total}
					enabledStructureData={enabledStructureData}
				/>
			);
		case "block_content--bloque_listado_programas":
			return (
				<BloqueListadoProgramas
					type={type}
					id={id}
					position={position}
					total={total}
				/>
			);
		case "block_content--bloque_texto_html":
			return <BloqueTextoHtml type={type} id={id} />;
		case "block_content--apertura_min_x_min":
			return (
				<BloqueMinutoXMinuto
					id={id}
					type={type}
					position={position}
					total={total}
				/>
			);
		case "block_content--minute_x_minute_standalone":
			return <BloqueMinutoXMinutoStandalone groupId={JSON.parse(id)} />;
		case "block_content--basic":
			return <BlockNewsletter type={type} id={id} />;
		case "block_content--bloque_apertura_top5":
			return (
				<BlockTopFive id={id} type={type} position={position} total={total} />
			);
		case "block_content--bloque_parrilla_programacion":
			return (
				<GridLoader
					type={type}
					id={id}
					position={position}
					total={total}
					params={params}
				/>
			);
		case "block_content--bloque_web_stories":
			return (
				<BloqueWebStories
					type={type}
					id={id}
					position={position}
					total={total}
				/>
			);
		case "block_content--tabs_the_talents":
			return (
				<BloqueTalentsTabs
					id={id}
					type={type}
					position={position}
					total={total}
				/>
			);
		case "block_content--tabs_de_programas":
			return (
				<BloqueProgramas
					type={type}
					id={id}
					pagePath={pagePath || ""}
					position={position}
					total={total}
				/>
			);
		case "block_content--bloque_botonera_horizontal":
			return (
				<BloqueBotoneraHorizontal
					type={type}
					id={id}
					position={position}
					total={total}
				/>
			);
		case "block_content--apertura_hero":
			return (
				<BloqueHero
					type={type}
					id={id}
					position={position}
					total={total}
					enabledStructureData={enabledStructureData}
				/>
			);
		case "block_content--bloque_apertura_informativa":
			return <BloqueAperturaInf id={id} type={type} position={position} />;
		case "block_content--bloque_top_banner":
			return <BloqueTopBanner id={id} type={type} />;
		case "block_content--carrusel_video_vertical":
			return (
				<BloqueVideoVertical
					type={type}
					id={id}
					position={position}
					total={total}
				/>
			);
		case "block_content--bloque_universal":
			return (
				<BloqueUniversal
					type={type}
					id={id}
					position={position}
					total={total}
				/>
			);

		case "block_content--bloque_mapa_de_elecciones":
			return (
				<BloqueMapboxMap
					type={type}
					id={id}
					position={position}
					total={total}
				/>
			);
		case "block_content--bloque_independiente_contenido":
			return (
				<BloqueIndependiente
					type={type}
					id={id}
					position={position}
					total={total}
				/>
			);
		case "block_content--bloque_apertura_mosaico":
			return (
				<BloqueMosaico type={type} id={id} position={position} total={total} />
			);
		case "block_content--apertura_hero_grid":
			return (
				<BloqueHeroGrid
					type={type}
					id={id}
					position={position}
					total={total}
					enabledStructureData={enabledStructureData}
				/>
			);
		case "block_content--detail_storypage":
			return (
				<BloqueStoryPage
					pagePath={pagePath}
					type={type}
					position={position}
					total={total}
				/>
			);
		case "block_content--bloque_carrusel_notas":
			return (
				<BlockCarouselNews
					id={id}
					type={type}
					position={position}
					total={total}
				/>
			);
		case "block_content--detail_video":
			return (
				<BlockDetailVideoLoader
					type={type}
					id={id}
					position={position}
					total={total}
				/>
			);
		case "block_content--widget_title_timeline":
			return <BlockTitleTimeline id={id} type={type} />;
		case "block_content--bloque_home_salaprensa":
			return (
				<BlockPressRoom type={type} id={id} position={position} total={total} />
			);
		case "taxonomy_term--temas":
			return <h1>temas</h1>;
		case "block_content--discover":
			return <DiscoverLoader params={params} />;
		case "block_content--bloque_detail_talent":
			return (
				<BloqueDetailTalent
					id={id}
					type={type}
					position={position}
					total={total}
				/>
			);
		case "block_content--breadcrumb":
			// const color = id?.includes("-dark-") ? "dark" : "light";
			return <Breadcrumb getLasted themeColor={id?.includes("-dark-") ? "dark" : "light"} />;
		case "block_content--summary_title":
			return (
				<SectionTitle
					title={id?.split("--")[1]}
					haveBreadcrumb={id?.includes("-breadcrumb-")}
					isDark={id?.includes("-dark-")}
				/>
			);
		case "block_content--bloque_carrusel_slideshow":
			return (
				<BlockSlideShow id={id} type={type} position={position} total={total} />
			);
		case "node--liveblog": {
			const pinnedLiveblog = getPinnedById(id || "", content);
			const { isStreaming } = newsArt;
			const vidobj: any = {
				cmsId: undefined,
				contentUrl: undefined,
				term: ["liveblog"],
			};
			if (isStreaming) {
				const now = new Date();
				const splitStart = newsArt?.liveChannel?.liveInfo?.hour?.split(":");
				const splitEnd = newsArt?.liveChannel?.liveInfo?.end?.split(":");
				const startdate = now.setHours(
					splitStart?.[0] ?? 0,
					splitStart?.[1] ?? 0,
					0,
					0
				);
				const enddate = now.setHours(
					splitEnd?.[0] ?? 0,
					splitEnd?.[1] ?? 0,
					0,
					0
				);
				vidobj.cmsId = undefined;
				vidobj.title = newsArt?.title;
				vidobj.summary = newsArt?.body;
				vidobj.thumbnail = newsArt?.thumbnail;
				vidobj.uploadDate = newsArt?.datePublished;
				vidobj.duration = newsArt?.liveChannel?.liveInfo?.duration;
				vidobj.contentUrl = undefined;
				vidobj.isStreaming = isStreaming;
				vidobj.term = ["liveblog"];
				vidobj.isBroadcastEvent = isStreaming;
				vidobj.startDate = new Date(startdate)?.toISOString();
				vidobj.endDate = new Date(enddate)?.toISOString();
			}
			return (
				<>
					<LiveBlog entity={content} />
					<NewsArticle mainNews={newsArt} />
					{isStreaming && <VideoObject mainVid={vidobj} />}
					<BlockLiveBlog
						id={id}
						type={type}
						content={content}
						pinnedLiveBlog={pinnedLiveblog}
					/>
				</>
			);
		}
		case "block_content--bloque_mapas_toggle":
			return (
				<>
					<BlockToggleMap
						type={type}
						id={id}
						position={position}
						total={total}
					/>
				</>
			);
		default:
			console.error(`The block '${type}' doesn't exist`);
			return <></>;
	}
}
