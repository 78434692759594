import dynamic from "next/dynamic";
import fetchApolloData from "@/app/helpers/fetchApolloData/fetchApolloData";
import GetProgramasTabs from "@/app/queries/getProgramas";
import { upperCamelCase } from "@/app/helpers/upperCamelCase/upperCamelCase";
import { ProgramTabResponse } from "./interfaces/ProgramasResponse";
import programas from "@/app/styles/Blocks/BloqueProgramas.module.scss";
const Programs = dynamic(() => import("./Programs"));

type ComponentProps = {
	type: string;
	id: string;
	pagePath: string;
	position?: number;
	total?: number;
};

export const BloqueProgramas = async ({
	pagePath,
	id,
	position = 0,
	total,
	type,
}: ComponentProps) => {
	const { data, ...rest } = await fetchApolloData(GetProgramasTabs, {
		programTabId: id,
		pagePath,
	});
	if (rest?.error) {
		console.error(rest?.error);
	}
	if (!data.getProgramTab) return null;

	const { field_programas, title, field_tabs, cmsName }: ProgramTabResponse =
		data.getProgramTab;
	const blockType = type.replace("block_content--", "").split("_").join(" ");
	const blockDataLayer = {
		event: "select_content",
		// clickBloque: 0,
		colorBloque: "dark",
		content_name: `SHOW>${pagePath}`,
		contenidosBloque: `${field_programas?.length} contenidos`,
		countWidget: total,
		descripcionBloque: cmsName,
		estiloBloque: undefined,
		eventAction: "click",
		posicionBloque: position + 1 ? position + 1 : 0,
		subBloque: undefined,
		tamanoBloque: undefined,
		tipoBloque: upperCamelCase(blockType) ?? undefined,
		tipoElemento: "LinkImage",
		tituloBloque: title ?? undefined,
	};
	const loading = position === 0 || position === 1 ? "eager" : "lazy";

	return (
		<section
			style={{
				backgroundColor: "#00283C",
			}}
		>
			<div className={`${programas["programs"]}`}>
				<Programs
					fieldProgramas={field_programas}
					fieldTabs={field_tabs}
					title={title}
					loading={loading}
					blockData={blockDataLayer}
				/>
			</div>
		</section>
	);
};
export default BloqueProgramas;
