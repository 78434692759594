import styles from "@/app/styles/SectionTitle/SectionTitle.module.scss";

export const SectionTitle = async ({
	title,
	haveBreadcrumb,
	isDark = false,
}: any) => {
	return (
		<div className={isDark ? `${styles?.sectionDark}` : ``}>
			<div
				className={
					haveBreadcrumb
						? `${styles?.wrapper} ${styles?.breadcrumb} `
						: `${styles.wrapper}`
				}
			>
				<h1 className={styles.title}>{title.replace("-", "")}</h1>
			</div>
		</div>
	);
};
export default SectionTitle;
