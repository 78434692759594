import dynamic from "next/dynamic";
import fetchApolloData from "@/app/helpers/fetchApolloData/fetchApolloData";
import GetBlockUniversal from "@/app/queries/getBlockUniversal";
import { UniversalBlockType, paragItem } from "./types/UniversalBlock.type";
import { upperCamelCase } from "@/app/helpers/upperCamelCase/upperCamelCase";
import { BlockProps } from "@/app/types/BlockProps.type";
import classNames from "classnames";
import style from "@/app/styles/BloqueUniversal/BloqueUniversal.module.scss";
const SquareImage = dynamic(
	() => import("@/app/components/molecules/SquareImage/SquareImage")
);
const RectImage = dynamic(
	() => import("@/app/components/molecules/RectImage/RectImage")
);
const WidgetTitle = dynamic(
	() => import("@/app/components/molecules/WidgetTitle/WidgetTitle")
);
const WidgetTitleMedia = dynamic(
	() => import("@/app/components/molecules/WidgetTitle/WidgetTitleMedia")
);

const BloqueUniversal = async ({
	type,
	id,
	position = 0,
	total,
	standaloneDark = false,
	isStandalone = false,
}: BlockProps) => {
	const { data, ...rest } = await fetchApolloData(GetBlockUniversal, {
		getBlockUniversalId: id,
	});

	if (rest?.errors) {
		console.error(rest?.errors);
		return null;
	}

	const blockData: UniversalBlockType = data?.getBlockUniversal;
	const { isDark, hasTitle } = blockData ?? {};
	const isSquare = blockData?.aspectRatio === "1:1";
	const itemSize = blockData?.templateSize;

	const cardSize =
		blockData?.templateSize === "S"
			? "Small"
			: blockData?.templateSize === "M"
			? "Medium"
			: "Large";
	const lookFeel = blockData?.lookFeel;
	const isTimeline = lookFeel === "Timeline";
	const isNMedia = lookFeel === "N+ Media";

	const blockType =
		type?.replace("block_content--", "").split("_").join(" ") ?? "";
	const blockDataLayer = {
		event: "select_content",
		clickBloque: undefined,
		colorBloque: `${blockData?.isDark ? "dark" : "light"}`,
		contenidosBloque: `${blockData?.blockContent.length} contenidos`,
		countWidget: total,
		descripcionBloque: blockData?.cmsName,
		estiloBloque: blockData?.aspectRatio ?? undefined,
		eventAction: "click",
		posicionBloque: position + 1 ? position + 1 : 0,
		subBloque: undefined,
		tamanoBloque: cardSize ?? undefined,
		content_name: "IMAGE>",
		tipoBloque: upperCamelCase(blockType) ?? undefined,
		tipoElemento: "widget",
		tituloBloque: blockData?.blockTitle ?? undefined,
	};

	const lesThanTwo = blockData?.blockContent?.length < 3;

	const darkMode = isStandalone ? standaloneDark : isDark;

	return (
		<section
			className={classNames("block__universal", style?.universalSection, {
				[style?.dark]: darkMode,
				[style?.timeline]: isTimeline,
				[style?.universalSection__standalone]: isStandalone,
			})}
			style={{
				paddingTop: `${position === 0 ? "0px" : ""}`,
			}}
		>
			{hasTitle && !isNMedia && (
				<WidgetTitle
					title={blockData?.blockTitle}
					text={blockData?.blockSummary}
					lineColor={blockData?.borderColor}
					isDark={isDark}
				/>
			)}
			{hasTitle && isNMedia && (
				<WidgetTitleMedia
					title={blockData?.blockTitle}
					text={blockData?.blockSummary}
				/>
			)}
			<div
				className={
					hasTitle
						? `${style.universalSection__universalWrapper} ${style.universalSection__withTitle}`
						: `${style.universalSection__universalWrapper}`
				}
			>
				{isSquare ? (
					<div
						className={
							itemSize === "S"
								? `${style.universalSection__contentS}`
								: itemSize === "M"
								? `${style.universalSection__contentM}`
								: isStandalone
								? `${style.universalSection__contentL} ${style.universalSection__contentL__standalone}`
								: `${style.universalSection__contentL}`
						}
					>
						{blockData?.blockContent?.map((item: paragItem, index: number) => (
							<SquareImage
								key={item?.id}
								img={item?.thumbnail}
								link={item?.itemLink}
								size={itemSize}
								blockData={{
									...blockDataLayer,
									contentTitle:
										item?.thumbnail?.caption ?? item?.thumbnail?.alt,
								}}
								cardPosition={index + 1}
							/>
						))}
					</div>
				) : (
					<div
						className={
							itemSize === "S" && lesThanTwo
								? `${style.universalSection__contentRS} ${style.universalSection__contentLess}`
								: itemSize === "S"
								? `${style.universalSection__contentRS}`
								: itemSize === "M"
								? `${style.universalSection__contentRM}`
								: `${style.universalSection__contentRL}`
						}
					>
						{blockData?.blockContent?.map((item: paragItem, index: number) => (
							<RectImage
								key={item?.id}
								img={item?.thumbnail}
								link={item?.itemLink}
								size={itemSize}
								blockData={{
									...blockDataLayer,
									contentTitle:
										item?.thumbnail?.caption ?? item?.thumbnail?.alt,
								}}
								cardPosition={index + 1}
							/>
						))}
					</div>
				)}
			</div>
		</section>
	);
};
export default BloqueUniversal;
