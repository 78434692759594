import { gql } from "@apollo/client";
export default gql`
	query GetHeroGrid($heroGridId: String!) {
		getHeroGrid(heroGridId: $heroGridId) {
			blockTitle
			colorLine
			noContent
			subTitle
			theme
			cmsName
			variant
			content {
				newsInfo {
					altImage
					created
					description
					editorialTitle
					image_16_9_desktop
					image_4_3_desktop
					newsUrl
					tagBlock
					title
					type
					videoDuration
					cmsId
					status
					isLiveblog
				}
				principalContent {
					altImage
					created
					description
					editorialTitle
					image_16_9_desktop
					newsUrl
					tagBlock
					title
					videoDuration
					type
					cmsId
					status
					isLiveblog
				}
			}
		}
	}
`;
