import { CSSProperties } from "react";
import dynamic from "next/dynamic";
import fetchApolloData from "@/app/helpers/fetchApolloData/fetchApolloData";
import GetBloqueIndependiente from "@/app/queries/getBloqueIndependiente";
import { upperCamelCase } from "@/app/helpers/upperCamelCase/upperCamelCase";
import { IndependentResponse } from "./types/";
import independFull from "@/app/styles/Blocks/BloqueIndependiente.module.scss";
const WidgetTitle = dynamic(
	() => import("@/app/components/molecules/WidgetTitle/WidgetTitle")
);
const IndependentFullSize = dynamic(() => import("./IndependentFullSize"));

type ComponentProps = {
	type: string;
	id: string;
	position?: number;
	total?: number;
};

export const BloqueIndependiente = async ({
	type,
	id,
	position = 0,
	total,
}: ComponentProps) => {
	const { data, ...rest } = await fetchApolloData(GetBloqueIndependiente, {
		getIndependentFullSizeId: id,
		type,
		fromSite: "video",
	});

	if (rest?.error) {
		console.error(rest?.error);
	}
	if (!data?.getIndependentFullSize) return null;
	const {
		blockTitle,
		newsInfo,
		theme,
		lineColor,
		subTitle,
		cmsName,
	}: IndependentResponse = data?.getIndependentFullSize || {};

	const isDark = theme.toLowerCase() === "dark";
	const themeColor: any = {
		"--independTheme": `${
			theme.toLowerCase() === "light" ? "#083043" : "#EDF4F8"
		}`,
		"--wrapperContainer": `${
			theme.toLowerCase() === "light" ? "#fff" : "#00283B"
		}`,
		paddingBottom: isDark ? "24px" : "0",
		marginBottom: isDark ? "0" : "24px",
	};

	const blockType = type.replace("block_content--", "").split("_").join(" ");
	const blockDataLayer = {
		event: "select_content",
		clickBloque: undefined,
		colorBloque: theme,
		contenidosBloque: `${newsInfo.length} contenidos`,
		content_name: "NEW>",
		countWidget: total,
		descripcionBloque: cmsName,
		estiloBloque: undefined,
		eventAction: "click",
		posicionBloque: position + 1 ? position + 1 : 0,
		subBloque: undefined,
		tamanoBloque: undefined,
		tipoBloque: upperCamelCase(blockType) ?? undefined,
		tipoElemento: "widget",
		tituloBloque: blockTitle ?? undefined,
	};
	const loading =
		position === 0 || position === 1 || position === 2 ? "eager" : "lazy";

	return (
		<section
			className={`${independFull["independFull__mainWrapper"]}`}
			style={themeColor}
		>
			<div>
				<WidgetTitle
					title={blockTitle}
					text={subTitle}
					lineColor={lineColor}
					isDark={isDark}
				/>
				<IndependentFullSize
					newsInfo={newsInfo}
					theme={theme.toLowerCase()}
					loading={loading}
					blockData={blockDataLayer}
				/>
			</div>
		</section>
	);
};
export default BloqueIndependiente;
