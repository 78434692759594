import { CSSProperties } from "react";
import dynamic from "next/dynamic";
import fetchApolloData from "@/app/helpers/fetchApolloData/fetchApolloData";
import GetSlideShow from "@/app/queries/getSlideShow";
import { upperCamelCase } from "@/app/helpers/upperCamelCase/upperCamelCase";
import { GetSlideShowInterface } from "./interfaces/SlideShow.interface";
import slideShowStyle from "@/app/styles/Blocks/BlockSlideShow.module.scss";
import WidgetTitle from "../../molecules/WidgetTitle/WidgetTitle";
const SlideShow = dynamic(
	() => import("@/app/components/molecules/SlideShow/SlideShow")
);

type SlideShowProps = {
	type: string;
	id: string;
	position?: number;
	total?: number;
};
export const BlockSlideShow = async ({
	type,
	id,
	position = 0,
	total,
}: SlideShowProps) => {
	const { data, ...rest } = await fetchApolloData(GetSlideShow, {
		slideShowId: id,
	});
	if (rest?.error) console.error(rest?.error);
	if (!data) return <></>;
	const {
		cmsName,
		lineColor,
		subtitle,
		...slideShowRest
	}: GetSlideShowInterface = data?.getSlideShow;

	const blockTypeLayer =
		type?.replace("block_content--", "")?.split("_")?.join(" ") || "";

	const blockDataLayer = {
		contenidosBloque: `${slideShowRest?.imagesNews?.length} contenidos`,
		countWidget: `${total || 0}`,
		estiloBloque: slideShowRest.aspectRatio,
		event: "select_content",
		eventAction: "click",
		posicionBloque: position + 1 ? `${position + 1}` : "0",
		subBloque: undefined,
		tamanoBloque: undefined,
		tipoBloque: upperCamelCase(blockTypeLayer || "") ?? undefined,
		tipoElemento: "widget",
		tituloBloque: slideShowRest?.blockTitle ?? undefined,
		descripcionBloque: cmsName,
		colorBloque: slideShowRest?.theme,
		clickBloque: undefined,
		content_type: undefined,
		section: undefined,
		contentTitle: undefined,
	};
	const loading =
		parseInt(blockDataLayer?.posicionBloque) === 0 ||
		parseInt(blockDataLayer?.posicionBloque) === 1 ||
		parseInt(blockDataLayer?.posicionBloque) === 2
			? "eager"
			: "lazy";
	const isDark = slideShowRest?.theme === "dark";
	return (
		<section
			className={`${slideShowStyle["slideShow--background"]}`}
			style={
				{
					"--background-theme": `${!isDark ? "" : "#00283B"}`,
					marginBottom: isDark ? "0" : "24px",
					paddingBottom: isDark ? "24px" : "0",
				} as CSSProperties
			}
		>
			<div
				className={`${slideShowStyle.slideShow}`}
				style={
					{
						"--background-theme": `${
							slideShowRest.theme === "light" ? "" : "#00283B"
						}`,
					} as CSSProperties
				}
			>
				<WidgetTitle
					title={slideShowRest.blockTitle}
					text={subtitle}
					isDark={isDark}
					lineColor={lineColor}
				/>
				<SlideShow
					{...slideShowRest}
					dataLayer={blockDataLayer}
					loading={loading}
				/>
			</div>
		</section>
	);
};
