import { gql } from "@apollo/client";

export default gql`
	query GetHero($openingBlockId: String!) {
		getHero(openingBlockId: $openingBlockId) {
			id
			type
			drupalID
			blockTitle
			blockDescription
			configurationType
			playType
			borderColor
			isDark
			hasTitle
			lookAlternate
			cmsName
			liveAttr {
				title
				description
				code
			}
			content {
				contentType
				hasSummary
				isBreaking
				textBreaking
				isTapToPlay
				data {
					id
					title
					summary
					duration
					date
					channel
					cmsId
					path
					status
    				isLiveblog
					term {
						name
						url
					}
					thumbnail {
						imageUrl {
							webp
							shapes {
 								square
								vintageVertical
							}
						}
						imageDesktopUrl {
							webp
						}
						alt
						caption
						width
						height
					}
					isVideo
				}
			}
		}
	}
`;
